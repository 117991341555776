import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home/index.vue";

Vue.use(VueRouter);

// 自动引入路由模块
const files = require.context('@/router/modules', false, /\.js$/)
const modules = []
files.keys().forEach(key => {
  modules.push(...files(key).default)

})

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  ...modules,
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes,
});

export default router;
