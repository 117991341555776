const contentRouter = [{
	path: "/agreement",
	name: "Agreement",
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () =>
		import(/* webpackChunkName: "agreement" */ "@/views/Agreement/index.vue"),
},]

export default contentRouter
