<template>
    <div class="page">
        <Header />
        <Body />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/header.vue';
import Body from './components/body.vue';
import Footer from '@/components/footer.vue';
export default {
    metaInfo: {
        title: '梵羽国际瑜伽', // set a title
        meta: [
            {
                // set meta
                name: 'keyWords',
                content: '关于梵羽国际瑜伽',
            },
            {
                name: 'description',
                content:
                    '梵羽集团旗下品牌,深圳市瑜伽协会常务副会长单位,深圳瑜伽教练培训高端学府;2013年成立,旗下拥有100家+连锁分店及一所培训学院、一所商学院,分布于全国各区域。亚洲瑜伽联盟协会、深圳市瑜伽协会指定培训基地、【沈阳体育学院】官方认证瑜伽教硏基地,国內专业瑜伽舞蹈培训学府。',
            },
        ],
    },
    name: 'HomewebIndex',
    components: {
        Header,
        Body,
        Footer,
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
// Layout
.page {
    height: 100%;
    width: 100%;

    position: relative;
}
</style>