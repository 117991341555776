<template>
    <div class="page_body">
        <div class="body_title_1">线下约课练瑜伽、就来梵羽云APP</div>
        <div class="body_title_2">
            下载梵羽云app了解更多惊喜，支持IOS、安卓版本下载,
            可前往各大应用市场下载
        </div>
        <div class="body_downlod">
            <div class="black_btn" :class="[index == selectBtn ? ' black_btn_select' : '']" v-for="(item, index) in btnList" :key="index">
                <img class="black_btn_image" :src="item.url" @click="selectBtn = index" />
                <div class="black_btn_qr" v-show="index == selectBtn">
                    <img :src="item.code" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FaceBody',

    data() {
        return {
            btnList: [
                {
                    url: require('../../../assets/component/android.png'),
                    code: require('../../../assets/qrcode/android.png'),
                },
                {
                    url: require('../../../assets/component/apple.png'),
                    code: require('../../../assets/qrcode/ios.png'),
                },
                {
                    url: require('../../../assets/component/wx.png'),
                    code: require('../../../assets/qrcode/wx.png'),
                },
            ],
            selectBtn: 0,
        };
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="scss" scoped>
.page_body {
    width: 100%;
    height: 100vh;
    background: url('../../../assets/background/bg.png') no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;

    .body_title_1 {
        color: #fff;
        font-size: 55px;
        position: absolute;
        top: 350px;
        left: 140px;

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            border-bottom: 1px solid #a2a2a2;
            position: absolute;
            bottom: -30px;
            left: 0;
        }
    }

    .body_title_2 {
        width: 500px;
        color: #fff;
        font-size: 18px;
        line-height: 35px;
        position: absolute;
        top: 480px;
        left: 140px;
    }

    .body_downlod {
        display: flex;
        position: absolute;
        top: 570px;
        left: 140px;
    }
}

// 下载按钮
.black_btn {
    padding: 15px 25px;
    cursor: pointer;
    transition: 0.3s;
    .black_btn_image {
        width: 150px;
        height: 50px;
    }

    .black_btn_qr {
        width: 150px;
        height: 150px;
        background-color: #fff;
        margin-top: 10px;
        img {
            width: 100%;
        }
    }
}

.black_btn_select {
    height: 220px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 15px 25px;
    border-radius: 7px;
    overflow: hidden;
}
</style>