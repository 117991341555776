<template>
    <div class="page_header" id="PageHeader" ref="PageHeader">
        <div class="header_position">
            <img :src="logo[mode]" />
            <div class="nav_list">
                <div v-for="(item,index) in navList" :key="index" class="nav_item">
                    <div class="nav_title" @click="toLink(item.fun)">{{ item.title }}</div>
                    <div class="nav_item_select">
                        <div
                            class="item_select_item"
                            v-for="(chil,chilIndex) in item.children"
                            :key="chilIndex"
                            @click="toLink(chil.fun, chil.type)"
                        >{{ chil.title }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FaceHeader',

    data() {
        return {
            mode: 'light',
            logo: {
                light: require('../assets/logo/homeLogo.png'),
                dark: require('../assets/logo/homeLogo_dark.png'),
            },
            navList: [
                {
                    title: '首页',
                    fun: 'toHome',
                },
                {
                    title: '动态',
                    fun: 'toNews',
                },
                // {
                //     title: '相关业务',
                // },
                {
                    title: '学院',
                    fun: 'toSchool',
                },
                {
                    title: '关于我们',
                    children: [
                        {
                            title: '关于我们',
                            fun: 'toAbout',
                            type: 'about',
                        },
                        {
                            title: '联系我们',
                            fun: 'toAbout',
                            type: 'contact',
                        },
                        {
                            title: '加入我们',
                            fun: 'toAbout',
                            type: 'join',
                        },
                        {
                            title: '隐私政策',
                            fun: 'toAbout',
                            type: 'yinsi',
                        },
                        {
                            title: '服务协议',
                            fun: 'toAbout',
                            type: 'fuwu',
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        window.addEventListener('scroll', this.scrollListener);
        this.$refs.PageHeader.addEventListener('mouseenter', this.headerHover);
        this.$refs.PageHeader.addEventListener('mouseleave', this.headerOut);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollListener);
        // this.$refs.PageHeader.removeEventListener('mouseenter', this.headerHover);
        // this.$refs.PageHeader.removeEventListener('mouseleave', this.headerOut);
    },
    methods: {
        toLink(fun, type) {
            if (fun) {
                this[fun](type);
            }
        },
        toHome() {
            if (this.$route.name == 'Home') {
                this.$emit('Home', 'Home');
            } else {
                this.$router.push({
                    name: 'Home',
                });
            }
        },

        toNews() {
            if (this.$route.name == 'News') {
                this.$emit('News', 'News');
            } else {
                this.$router.push({
                    name: 'News',
                });
            }
        },
        toSchool() {
            if (this.$route.name == 'School') {
                this.$emit('School', 'School');
            } else {
                this.$router.push({
                    name: 'School',
                });
            }
        },
        toAbout(type) {
            if (this.$route.name == 'Agreement') {
                this.$emit('Agreement', type);
            } else {
                this.$router.push({
                    name: 'Agreement',
                    query: {
                        type: type,
                    },
                });
            }
        },
        scrollListener() {
            let header = document.querySelector('#PageHeader');
            if (window.scrollY > 0) {
                if (window.scrollY > 200) {
                    header.classList.remove('bian');
                    header.classList.add('hidden_header');
                } else {
                    header.classList.add('bian');
                    header.classList.remove('hidden_header');
                }
            } else {
                header.classList.remove('bian');
            }
        },
        headerHover() {
            let header = document.querySelector('#PageHeader');
            header.classList.add('white_header');
            this.mode = 'dark';
        },
        headerOut() {
            let header = document.querySelector('#PageHeader');
            header.classList.remove('white_header');
            this.mode = 'light';
        },
    },
};
</script>

<style lang="scss" scoped>
$header-color: rgba(0, 0, 0, 0.7);
$nav-item-width: 100px;
$nav-item-height: 30px;

.page_header {
    width: 100%;
    height: 100px;
    line-height: 100px;
    color: #fff;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    transition: 0.3s;

    padding: 0 134px;
    box-sizing: border-box;
    z-index: 99;
    cursor: pointer;

    .header_position {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            height: 50px;
        }

        .nav_list {
            display: flex;
            .nav_item {
                width: $nav-item-width;
                height: $nav-item-height;
                line-height: $nav-item-height;
                text-align: center;
                font-size: 17px;
                // font-weight: bold;
                margin-right: 30px;
                border-radius: 4px;
                cursor: pointer;
                transition: all 0.2s;

                &:hover {
                    .nav_item_select {
                        height: auto;
                    }

                    .nav_title {
                        &::after {
                            width: 100%;
                        }
                    }
                }

                .nav_title {
                    position: relative;
                    &::after {
                        content: '';
                        width: 0;
                        height: 5px;
                        background-color: #67236a;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        transition: width 0.2s;
                    }
                }

                .nav_item_select {
                    padding-top: 15px;
                    width: 100%;
                    height: 0;
                    overflow: hidden;

                    .item_select_item {
                        font-size: 14px;
                        font-weight: initial;
                        color: #6b6d6d;
                        padding-top: 10px;
                        &:hover {
                            color: #333;
                        }
                    }
                }
            }
        }
    }
}

// 导航栏渐变
.bian {
    padding: 10px 134px;
    background-color: $header-color;
}

.hidden_header {
    height: 0;
    overflow: hidden;
}

.white_header {
    height: 300px;
    background-color: #fff;
    color: #333;
}
</style>