import { app } from "@/settings";

let DEBUG = app.DEBUG; // 是否开启打印

// /**
//  * @date 2021/10/14下午1:40:19
//  * @description 控制打印 debug 
//  * @param {number} params
//  * @return {void}
//  */
// export function LOG(text, type = 'log') {
//     if (!DEBUG) {
//         return false;
//     } else {
//         console[type](text);
//     }
// }

export default {
    /**
     * @date 2021/10/14下午2:01:34
     * @description 控制打印 debug 
     * @param {string} text 打印内容
     * @param {string} type console 类型
     * @return {void}
     */
    $Log: function (text, type = 'log') {
        if (!DEBUG) {
            return false;
        } else {
            console[type](text);
        }
    }
}