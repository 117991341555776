const contentRouter = [{
    path: "/school",
    name: "School",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "school" */ "@/views/School/index.vue"),
},]

export default contentRouter
