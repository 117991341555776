<template>
    <div class="page_footer">
        <div class="page_footer_box">
            <div class="information">
                <div class="footer_left">
                    <div class="title-bar company_name">梵羽国际瑜伽</div>
                    <div class="info_sub">深圳市梵羽瑜伽文化传播有限公司</div>
                    <div class="info_sub">地址：深圳市龙华区龙华街道三联社区金銮国际商务大厦3期A栋401</div>
                    <div class="info_sub">联系方式：0755-8671-3491</div>
                    <div class="info_sub">食品经营许可证号：JY14403110692911</div>
                </div>
                <div class="footer_right">
                    <div class="footer_nav">
                        <div class="right-item" v-for="(item,index) in footerNav" :key="index">
                            <div class="title-bar" @click="toLink(item.type)">{{ item.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_web_info">
                <span>www.fanyu.cn Copyright © 2012-2021 © 广东梵羽云商业科技有限公司保留所有解释权</span>
                <span>丨</span>
                <span>
                    <img src="../assets/logo/police.png" style="width:10px;height:10px" />
                </span>
                <a href="http://beian.miit.gov.cn/" target="_blank" style="color: #fff">粤ICP备20067522号</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FaceFooter',

    data() {
        return {
            footerNav: [
                {
                    title: '关于我们',
                    type: 'about',
                },
                {
                    title: '联系我们',
                    type: 'contact',
                },
                {
                    title: '加入我们',
                    type: 'join',
                },
                {
                    title: '隐私政策',
                    type: 'yinsi',
                },
                {
                    title: '服务协议',
                    type: 'fuwu',
                },
            ],
        };
    },

    methods: {
        toLink(type) {
            if (type) {
                if (this.$route.name == 'Agreement') {
                    this.$emit('Agreement', type);
                } else {
                    this.$router.push({
                        name: 'Agreement',
                        query: {
                            type: type,
                        },
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$footer-color: #333333;

.page_footer {
    width: 100%;
    height: 300px;
    background-color: $footer-color;
    padding: 40px 0;
    box-sizing: border-box;
    position: relative;

    .page_footer_box {
        position: relative;
        width: 80%;
        margin: 0 auto;
    }

    .information {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #b5b5b5;
        padding-bottom: 25px;

        .footer_left {
            .company_name {
                color: #fff;
                font-size: 25px;
            }

            .info_sub {
                color: #dedcde;
                font-size: 14px;
                margin-top: 12px;
            }
        }

        .footer_nav {
            display: flex;
            flex-wrap: wrap;
            color: #fff;
            font-size: 18px;
            .right-item {
                width: 33.3%;
                cursor: pointer;
                &:nth-last-child(-n + 2) {
                    margin-top: 17px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .footer_web_info {
        font-size: 13px;
        color: #dedcde;
        text-align: center;
        padding-top: 10px;
    }
}

// 标题前栏竖状柱
.title-bar {
    position: relative;
    padding-left: 10px;
    &::after {
        content: '';
        height: 80%;
        width: 5px;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 12%;
        bottom: 0;
    }
}
</style>