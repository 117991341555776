const contentRouter = [{
    path: "/news",
    name: "News",
    component: () =>
        import(/* webpackChunkName: "news" */ "@/views/news/index.vue"),
}, {
    path: "/detail",
    name: "Detail",
    component: () =>
        import(/* webpackChunkName: "news" */ "@/views/news/detail.vue"),
},]

export default contentRouter
