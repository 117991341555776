import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/utils/prototype/index"
import MetaInfo from 'vue-meta-info'
import 'element-ui/lib/theme-chalk/index.css';

import { Empty, Pagination } from 'element-ui';

Vue.use(Empty)
Vue.use(Pagination)


Vue.use(MetaInfo)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  //添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");

console.log("1");
